import { LivechatSessionType, LivechatState } from "./types";
import { filterUniqueTags, getSelectedChatObject } from "@/helperMethods/livechat/util";

import { GetterTree } from "vuex/types";
import { LivechatType } from "./mutations";
import { RootState } from "@/store/types";
import _ from "lodash";
import { sortMessagesAscending } from "@/helperMethods/livechat/orderMessage";

const getters: GetterTree<LivechatState, RootState> = {
  getConnectedAgents(state) {
    return state.connectedAgents;
  },
  getSocketConnectionStatus(state, getters, rootState): string {
    return state.socketConnection;
  },
  getAgentStatus(state, getters, rootState): string {
    return state.agentStatus;
  },
  isAgentOnline(state, getters, rootState): boolean {
    return state.agentStatus === "ONLINE";
  },
  activeTabName(state, getters, rootState): string {
    return state.activeTabName;
  },
  isQueueTab(state, getters, rootState) {
    const isQueueTab = getters.activeTabName === "queue";
    return isQueueTab;
  },
  isResolvedTab(state, getters, rootState) {
    const isResolvedTab = getters.activeTabName === "resolved";
    return isResolvedTab;
  },
  isMonitorTab(state, getters, rootState) {
    const isMonitorTab = getters.activeTabName === "monitor";
    return isMonitorTab;
  },
  selectedMonitorChatPartitionKey(state, getters, rootState) {
    return state.selectedMonitorChatPartitionKey;
  },
  selectedMonitorChatRowKey(state, getters, rootState) {
    return state.selectedMonitorChatRowKey;
  },
  selectedChatId(state, getters, rootState) {
    return state.selectedChatId;
  },
  selectedChat(state, getters, rootState) {
    let selectedChat;
    const currentTab: LivechatType = getters.activeTabName;
    const tabRelatedChats = getSelectedChatObject(currentTab);
    const tabRelatedChatsIsArray = _.isArray(tabRelatedChats);
    if (tabRelatedChatsIsArray) {
      selectedChat = tabRelatedChats.find((chat: any) => chat.RowKey === getters.selectedChatId);
    } else {
      selectedChat = tabRelatedChats[getters.selectedChatId];
    }

    const sortedChat = sortMessagesAscending(selectedChat);
    return sortedChat;
  },
  getTypingIndicator(state, getters, rootState) {
    const selectedChat = getters.selectedChat;
    if (_.isEmpty(selectedChat)) {
      return false;
    }
    return state.typingIndicatorUserIds[selectedChat.user_id];
  },

  // TODO: to implement in future
  selectedChatInteractions(state, getters, rootState) {
    const selectedChat = getters.selectedChat;
    const sortedChat = sortMessagesAscending(selectedChat);
    return sortedChat;
  },

  livechatArray(state, getters, rootState) {
    return state.livechats;
  },
  queueChatsArray(state, getters, rootState) {
    const chatsArray = Object.values(state.queueChatObject);
    return chatsArray.filter((chat) => {
      return !chat.isRemoved;
    });
  },
  unfilteredResolvedChatsArray(state, getters, rootState) {
    return state.unfilteredResolvedChatsArray;
  },
  unfilteredAbandonedChatsArray(state, getters, rootState) {
    return state.unfilteredAbandonedChatsArray;
  },
  resolvedChatsArray(state, getters, rootState) {
    return state.resolvedChatsArray;
  },
  abandonedChatsArray(state, getters, rootState) {
    return state.abandonedChatsArray;
  },
  monitorChatsArray(state, getters, rootState) {
    return state.monitorChatsArray;
  },
  queueFilteredChatsArray(state, getters, rootState) {
    const queueChatsArray = getters.queueChatsArray as LivechatSessionType[];
    const { chatSources = [], searchTerm = "" } = getters.queueChatFilters;

    if (_.isEmpty(queueChatsArray)) {
      return [];
    }

    return _.filter(queueChatsArray, (chat) => {
      const { resolved } = chat;

      // Filter away resolved chats
      if (!chat || resolved) {
        return false;
      }

      let matchChatSource = true;
      if (!_.isEmpty(chatSources)) {
        const channel = _.get(chat, "stateVariables.channel", null);
        matchChatSource = _.includes(chatSources, channel);
      }

      let matchSearchTerm = true;
      if (searchTerm) {
        const stringified = JSON.stringify(Object.values(chat));
        matchSearchTerm = _.includes(stringified.toLowerCase(), searchTerm.toLowerCase());
      }

      return matchSearchTerm && matchChatSource;
    });
  },
  queueAssignedChatsArray(state, getters, rootState) {
    const queuedChats = getters.queueFilteredChatsArray as LivechatSessionType[];
    const agentEmail = rootState.profile.email;
    const assigned = queuedChats.filter((chat) => {
      const agents = (chat.agents || []).map((email) => email.toLowerCase());
      return agents.includes(agentEmail?.toLowerCase());
    });
    return assigned;
  },
  queueUnassignedChatsArray(state, getters, rootState) {
    // Skip if assignment is broadcast
    const handover = rootState.modules?.handover ?? {};
    const {
      chat_routing_mode: routingMode = "broadcast",
      show_users_waiting_for_assignment: showQueue = true,
      handoverRouting = {},
    } = handover;
    // For assignment routing, if the showUsersWaitingForAssignment option is enabled, users should manually join chat.
    if (routingMode === "assignment" && !showQueue) {
      return [];
    }

    // Normal filters
    // const routeByDepartment = handoverRouting?.byDepartment ?? false;
    // const agentDepartments = rootState.profile?.app_metadata?.departments ?? [];
    const queuedChats = getters.queueFilteredChatsArray as LivechatSessionType[];
    const unassignedChats = queuedChats.filter((chat) => {
      if (chat.resolved) {
        return false;
      }
      // if (routeByDepartment && agentDepartments) {
      //   const userDepartment = chat?.stateVariables?.userQueryTopic;
      //   // agentHasDepartment cant be only defined by agentDepartments length more than 1, if agentDepartments has only "general" department, it counted as has no department
      //   // ref: "general" dept pushed to agentDepartments in getters.getUserAuthentication
      //   // TODO: can review, whether there is requirement for pushing "general" in agentDepartments
      //   const agentHasDepartment = !(
      //     _.isEmpty(agentDepartments) ||
      //     (agentDepartments.length === 1 && agentDepartments.includes("general"))
      //   );
      //   const notMatchedWithAgentDepartment =
      //     agentHasDepartment && !_.includes(agentDepartments, userDepartment);
      //   if (notMatchedWithAgentDepartment) {
      //     return false;
      //   }
      // }
      return _.isEmpty(chat.agents);
    });
    return unassignedChats;
  },

  // DONE
  queueBadgeNumber(state, getters, rootState) {
    if (getters.isRoutingAssignmentMode) {
      return getters.queueAssignedChatsArray.length;
    } else {
      return getters.queueAssignedChatsArray.length + state.queueChatCount;
    }
  },
  queueUnassignedBadgeNumber(state, getters, rootState) {
    return state.queueChatCount;
  },
  resolvedBadgeNumber(state, getters, rootState) {
    return state.resolvedChatCount;
  },
  abandonedBadgeNumber(state, getters, rootState) {
    return state.abandonedChatCount;
  },
  monitorBadgeNumber(state, getters, rootState) {
    return getters.monitorChatsArray.length;
  },
  queueChatFilters(state, getters, rootState) {
    return state.queueChatFilters;
  },
  resolvedChatFilters(state, getters, rootState) {
    return state.resolvedChatFilters;
  },
  abandonedChatFilters(state, getters, rootState) {
    return state.abandonedChatFilters;
  },
  monitorChatFilters(state, getters, rootState) {
    return state.monitorChatFilters;
  },
  // DONE
  queueChatTags(state, getters, rootState) {
    const queueChatsArray = getters.queueChatsArray;
    if (!queueChatsArray || !Array.isArray(queueChatsArray)) {
      return [];
    }

    const finalTags = filterUniqueTags(queueChatsArray);

    return finalTags;
  },
  resolvedChatTags(state, getters, rootState) {
    const resolvedChatsArray = getters.resolvedChatsArray;
    if (!resolvedChatsArray || !Array.isArray(resolvedChatsArray)) {
      return [];
    }

    const finalTags = filterUniqueTags(resolvedChatsArray);

    return finalTags;
  },
  abandonedChatTags(state, getters, rootState) {
    const abandonedChatsArray = getters.abandonedChatsArray;
    if (!abandonedChatsArray || !Array.isArray(abandonedChatsArray)) {
      return [];
    }

    const finalTags = filterUniqueTags(abandonedChatsArray);

    return finalTags;
  },
  monitorChatTags(state, getters, rootState) {
    const monitorChatsArray = getters.monitorChatsArray;
    if (!monitorChatsArray || !Array.isArray(monitorChatsArray)) {
      return [];
    }

    const finalTags = filterUniqueTags(monitorChatsArray);

    return finalTags;
  },

  // DONE
  fetchQueueChatLoading(state, getters, rootState) {
    return state.fetchQueueChatLoading;
  },
  fetchResolvedChatLoading(state, getters, rootState) {
    return state.fetchResolvedChatLoading;
  },
  fetchAbandonedChatLoading(state, getters, rootState) {
    return state.fetchAbandonedChatLoading;
  },
  fetchMonitorChatLoading(state, getters, rootState) {
    return state.fetchMonitorChatLoading;
  },
  sendEmailLoading(state, getters, rootState) {
    return state.sendEmailLoading;
  },
  getAdditionalAgentStatus: (state, getters, rootState) => {
    const additionalAgentStatus = _.get(rootState, "modules.handover.additionalAgentStatus", {});

    return additionalAgentStatus;
  },
  dataCollectionFormEnabled: (state, getters, rootState) => {
    return _.get(rootState, "modules.handover.dataCollectionForm.enabled", false);
  },
  getRoutingMode: (state, getters, rootState) => {
    return _.get(rootState, "modules.handover.chat_routing_mode", "broadcast");
  },
  isRoutingAssignmentMode: (state, getters, rootState) => {
    const isRoutingEqualToAssignment =
      _.get(rootState, "modules.handover.chat_routing_mode", "broadcast") === "assignment";
    return isRoutingEqualToAssignment;
  },
  isRoutingBroadcastMode: (state, getters, rootState) => {
    const isRoutingEqualToBroadcast =
      _.get(rootState, "modules.handover.chat_routing_mode", "broadcast") === "broadcast";
    return isRoutingEqualToBroadcast;
  },
  getDisableMonitorTabOption: (state, getters, rootState) => {
    return _.get(rootState, "modules.handover.disableMonitorTab", false);
  },
  getDisableAbandonedTabOption: (state, getters, rootState) => {
    return _.get(rootState, "modules.handover.disableAbandonedTab", true);
  },
  fieldDataForAutoFormPopulation(state, getters, rootState) {
    return state.fieldData;
  },
  isShowAgentJoinLeaveEventsEnabled(state, getters, rootState) {
    return _.get(rootState, "modules.handover.show_agent_join_leave_events", true);
  },
  getOnlineAgents(state, getters, rootState): string[] {
    return getters.getConnectedAgents
      .filter((agent: any) => agent.status === "ONLINE")
      .map((agent: any) => agent.email);
  },
  getOfflineAgents(state, getters, rootState): string[] {
    return getters.getConnectedAgents
      .filter((agent: any) => agent.status !== "ONLINE")
      .map((agent: any) => agent.email);
  },
  isUsingResolvedFilter(state): Boolean {
    const keysToCompare = ["searchTerm", "contextHistory", "tags", "chatSources", "involvedMe"];
    return !_.every(keysToCompare, (key) => {
      const resolvedFilterValue = _.get(state.resolvedChatFilters, key, false);
      const defaultFilterValue = _.get(state.defaultFilters, key, false);
      return _.isEqual(resolvedFilterValue, defaultFilterValue);
    });
  },
  isUsingAbandonedFilter(state): Boolean {
    const keysToCompare = ["searchTerm", "contextHistory", "tags", "chatSources"];
    return !_.every(keysToCompare, (key) => {
      const abandonedFilterValue = _.get(state.abandonedChatFilters, key, false);
      const defaultFilterValue = _.get(state.defaultFilters, key, false);
      return _.isEqual(abandonedFilterValue, defaultFilterValue);
    });
  },
  agentAllowedQueues(state, getter, rootState): String[] {
    const agentDepartments = _.get(
      rootState,
      "profile['https://login.callcenter.smg.sg/appmetadata'].departments",
      ["general"]
    );

    const agentId = _.get(rootState, "profile['https://login.callcenter.smg.sg/email']", null);
    if (!agentId) return agentDepartments;

    const chatLimitPerAgent = _.get(rootState, "modules.handover.chatLimitPerAgent", []);
    if (_.isEmpty(chatLimitPerAgent)) return agentDepartments;

    const lowercasedAgentId = agentId?.toLowerCase();
    const agentChatLimit = _.isArray(chatLimitPerAgent)
      ? chatLimitPerAgent.find((setting) => setting.agentId.toLowerCase() === lowercasedAgentId)
      : undefined;

    const allowedQueues = agentChatLimit?.queues || [];
    const myAllowedQueues = _.isEmpty(allowedQueues) ? agentDepartments : allowedQueues;

    // To match with the replaced getter "userDepartment"
    const uniqueQueues: string[] = _.uniq(myAllowedQueues);
    const lowerCaseQueues = uniqueQueues.map((word) => word.toLowerCase());

    return lowerCaseQueues;
  },
};

export default getters;
