import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { FaqState } from "./types";
import _ from "lodash";

const getters: GetterTree<FaqState, RootState> = {
  rasaEnabled(state, getters, rootState) {
    return _.get(rootState, "modules.faq.Rasa.enabled", false);
  },
  elsaEnabled(state, getters, rootState) {
    return _.get(rootState, "modules.faq.Elsa.enabled", false);
  },
  openAIGenerateExamplesEnabled(state, getters, rootState) {
    return (
      _.get(rootState, "modules.pretrained_skills.OpenAI.enabled", false) &&
      _.get(rootState, "modules.pretrained_skills.OpenAI.exampleGeneration.enabled", false)
    );
  },
  getFaqTopics(state, getters) {
    return _.get(state, "topics");
  },
  getTrainingInProgress(state) {
    return _.get(state, "trainingInProgress");
  },
  getPublishingInProgress(state) {
    return _.get(state, "publishingInProgress");
  },
  getSelectedTopics(state) {
    return _.get(state, "selectedTopics");
  },
  userDepartment(state, getters, rootState) {
    const userDepartment = _.get(
      rootState,
      "profile['https://login.callcenter.smg.sg/appmetadata'].departments",
      ["general"]
    );

    const uniqueUserDepartment: string[] = _.uniq(userDepartment);
    const lowerCaseUserDepartment = uniqueUserDepartment.map((word) => word.toLowerCase());

    return lowerCaseUserDepartment;
  },
  evaluationModuleEnabled(state, getters, rootState) {
    return _.get(rootState, "modules.evaluation.enabled", false);
  },
  groupByTopicIsEnabled(state, getters, rootState) {
    return _.get(rootState, "modules.faq.groupByTopic", false);
  },
  getConflictDifferenceThreshold(state, getters, rootState) {
    return _.get(rootState, "modules.faq.showConflictDifferenceThreshold", 0.5);
  },
  currentTrainingData(state, getters, rootState) {
    const rawTrainingData = _.get(rootState, "training.all", []);
    const searchedTrainingData = _.get(rootState, "training.searched", []);

    const backendSearch = _.get(state, "backendSearch", "");
    if (backendSearch) {
      return searchedTrainingData;
    } else {
      return rawTrainingData;
    }
  },
  intents(state, getters, rootState) {
    const intents = _.chain(getters.currentTrainingData)
      .filter((intent) => intent.archived == false)
      .countBy((intent) => intent.prediction)
      .toPairs()
      .orderBy([(intent) => intent[1]], ["desc"])
      .value();

    return intents || [];
  },
  getTrainingDownloadFileType(state, getters, rootState) {
    return _.get(rootState, "modules.faq.trainingDownloadFileType", "XLSX");
  },
};

export default getters;
